import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import Produits from "../components/Index/Produits"
import UnProjet from "../components/Common/UnProjet"
import { Helmet } from "react-helmet"

const NosProduits = () => {
  return (
    <Layout>
      <Helmet>
        <title>Seekreet | Produits & solutions de data management</title>
        <meta name="description" content="Solutions logicielles et matérielles dédiées à la culture qualité. Compliance, qhse, qualité, qvct, partage d’informations. Vos avez vos process, nous les digitalisons ! Gagnez en temps et en efficacité !" />
      </Helmet>
      <Navbar />
      <PageBanner
          pageTitle="Produits" 
          homePageText="Accueil" 
          homePageUrl="/" 
          activePageText="Produits" 
      />
      <Produits />
      <UnProjet />
      <Footer />
    </Layout>
  )
}

export default NosProduits